h1, p {
    font-family: Lato;
  }
  
  .App {
    text-align: center;
    display: flex;
    height: 700px;
    justify-content: space-between;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }
  
  .App-title {
    font-size: 1.5em;
  }
  
  .App-intro {
    font-size: large;
  }
  
  @keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  .done{
    border: 1px solid black;
    /* padding: 10px; */
    margin-top: 20px;
    background-color: #888888;
    float: right;
    width: 48%;
    box-shadow: 10px 10px 8px #888888;
  }
  
  .todos{
    border: 1px solid black;
    /* padding: 10px; */
    margin-top: 20px;
    background-color: #888888;
    float: left;
    width: 48%;
    box-shadow: 10px 10px 8px #888888;
  }
  
  
  
  .on-drag{
    border: solid 3px gold;
    background-color: white;
    opacity: 0.2;
    box-shadow: 10px 10px 8px black;
  }
 
  .headerActive{
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    padding: 10px;
    border: 1px solid;
    position: relative;
    margin-bottom: 10px;
    background-color: #b2fab4;
    border-bottom: 1px solid #519657
  }
  
  .itemActive{
    padding: 10px;
    /* border: 2px solid black; */
    width: auto;
    margin: 10px;
    box-shadow: 2px 2px 2px black;
    background-color: #b2fab4;
    font-family: "sans serif";
    border-bottom: 1px solid #519657;
  }

  .headerAll{
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    padding: 10px;
    border: 1px solid;
    position: relative;
    margin-bottom: 10px;
    background-color: #ff867c;
    border-bottom: 1px solid #b61827;
  }

  .itemAll{
    padding: 10px;
    /* border: 2px solid black; */
    width: auto;
    margin: 10px;
    box-shadow: 2px 2px 2px black;
    background-color: #ff867c;
    font-family: "sans serif";
    border-bottom: 1px solid #b61827;
  }